import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectOption } from '../../models/select-option';

@UntilDestroy()
@Component({
  selector: 'app-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss'],
})
export class SelectFilterComponent implements OnInit {
  @Input() field: string;
  @Input() placeholder: string;
  @Input() options: SelectOption<string>[];
  @Input() multiple = false;
  @Input() imageField: string;

  values: any[];

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe((params) => {
      this.values =
        params
          .get(this.field)
          ?.split(',')
          ?.map((item) => this.options.find((option) => option.value === item)) ?? [];
      if (!this.multiple) this.values = (this.values || [])[0];
    });
  }

  async valueChanged(items) {
    if (!this.multiple) items = items ? [items] : [];
    items = items?.map((item) => item?.value);
    const queryParams: any = {};
    queryParams[this.field] = items.join(',') || null;
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
