<div
  class="container"
  [appLoading]="isLoading"
  [class.condensed]="condensed"
  appDragDrop
  (click)="fileInput.click()"
  (fileDropped)="onFileSelected($event['0'])"
>
  <div
    *ngIf="fileDetails?.dataUrl || dataUrl"
    class="img-wrapper center"
    [class.initial-image]="(!!dataUrl && !fileDetails) || !showInfo"
    [ngClass]="shape"
  >
    <img
      *ngIf="(fileDetails?.type | isImage) || (dataType | isImage)"
      class="picture"
      [src]="fileDetails?.dataUrl || dataUrl"
    />
    <video *ngIf="(fileDetails?.type | isVideo) || (dataType | isVideo)" controls style="width: 100%">
      <source [src]="fileDetails?.dataUrl || dataUrl" />
    </video>
    <audio *ngIf="(fileDetails?.type | isAudio) || (dataType | isAudio)" controls style="width: 100%">
      <source [src]="fileDetails?.dataUrl || dataUrl" />
    </audio>
  </div>

  <div class="center no-file" *ngIf="!fileDetails && !dataUrl" [class.has-image]="dataUrl" [ngClass]="shape">
    <svg
      class="upload-icon"
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <g><rect fill="none" height="24" width="24" /></g>
      <g>
        <path
          d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M7,9l1.41,1.41L11,7.83V16h2V7.83l2.59,2.58L17,9l-5-5L7,9z"
        />
      </g>
    </svg>
    <p>Drop your file here, or <span class="blue">click to browse</span></p>
    <p class="message" *ngIf="message">{{ message }}</p>
  </div>

  <div *ngIf="fileDetails && showInfo" class="file-info" (click)="$event.stopPropagation()">
    <span class="type">{{ fileDetails.extension }}</span>
    <p class="name">{{ fileDetails?.filename }}</p>
    <p class="size">
      {{ fileDetails?.size | filesize }}
      <ng-container *ngIf="fileDetails.duration"> {{ fileDetails.duration | duration }}</ng-container>
      <ng-container *ngIf="fileDetails.width"> ({{ fileDetails.width }} x {{ fileDetails.height }}px) </ng-container>
    </p>
    <div class="actions">
      <button mat-icon-button color="warn" (click)="onCancel($event)" matTooltip="Remove file">
        <i class="icon">delete</i>
      </button>
    </div>
  </div>

  <input
    type="file"
    #fileInput
    [accept]="accept"
    (change)="onFileSelected($event.target.files['0'])"
    style="display: none"
  />
</div>
