import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, Observable, concat, of } from 'rxjs';
import { startWith, distinctUntilChanged, debounceTime, tap, switchMap, map, catchError } from 'rxjs/operators';
import { AutocompleteService } from '../../services/autocomplete.service';

@UntilDestroy()
@Component({
  selector: 'app-select-http-filter',
  templateUrl: './select-http-filter.component.html',
  styleUrls: ['./select-http-filter.component.scss'],
})
export class SelectHttpFilterComponent implements OnInit {
  @ViewChild(NgSelectComponent) select: NgSelectComponent;

  input$ = new Subject<string>();

  @Input() url: string;
  @Input() placeholder = '';
  @Input() field;
  @Input() labelField: string;
  @Input() valueField: string;
  @Input() imageField: string;
  @Input() additionalFields: string; // This fields will be passed to the "summary" query param
  @Input() multiple = false;

  @Input() queryParams: { [key: string]: string } = {};
  @Input() maxSuggestions: number;
  @Input() labelFieldFunction: (item: any) => string;

  isLoading = false;
  items$: Observable<any[]>;
  selectedItems: any[];

  constructor(
    private autocompleteService: AutocompleteService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    const ids = this.route.snapshot.queryParamMap.get(this.field);
    if (ids && ids.length > 0) this.loadInitial(ids);

    this.items$ = concat(
      of([]),
      this.input$.pipe(
        startWith(''),
        distinctUntilChanged(),
        debounceTime(400),
        tap(() => (this.isLoading = true)),
        switchMap((value) => this._filter(value))
      )
    );

    this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe((params) => {
      const routeIds = params.get(this.field);
      if (!routeIds || routeIds.length === 0) {
        this.selectedItems = [];
      }
    });
  }

  async loadInitial(ids: string) {
    const items = await this.autocompleteService.findByIds(this.url, ids).toPromise();
    this.selectedItems = items.map((item) => ({
      label: item[this.labelField] || this.labelFieldFunction(item),
      value: item[this.valueField],
      ...(this.imageField && { image: item[this.imageField] }),
    }));
    if (!this.multiple) this.selectedItems = (this.selectedItems || [])[0];
  }

  private _filter(value: string) {
    const filterValue = (value || '').toLowerCase();
    return this.autocompleteService
      .search({
        url: this.url,
        search: filterValue,
        valueField: this.valueField,
        labelField: this.labelField,
        imageField: this.imageField,
        additionalFields: this.additionalFields,
        queryParams: this.queryParams,
        maxSuggestions: this.maxSuggestions,
      })
      .pipe(
        map((val) =>
          val.map((item) => ({
            label: item[this.labelField] || this.labelFieldFunction(item),
            value: item[this.valueField],
            ...(this.imageField && { image: item[this.imageField] }),
          }))
        ),
        catchError(() => of([])),
        tap(() => (this.isLoading = false))
      );
  }

  async valueChanged(items) {
    if (!this.multiple) items = items ? [items] : [];
    items = items.map((item) => item.value);
    const queryParams: any = {};
    queryParams[this.field] = items.join(',') || null;
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
