<nav [class.condensed]="condensed">
  <button mat-icon-button class="collapse-icon" *ngIf="!isMobile" (click)="isCondensed = !isCondensed">
    <i class="icon">{{ condensed ? 'chevron_right' : 'chevron_left' }}</i>
  </button>

  <img src="/assets/images/logo.svg" alt="Logo" class="logo" />

  <button mat-button class="close-icon" (click)="menuToggle.emit()" *ngIf="isMobile">
    <i class="icon">close</i>
  </button>

  <section class="navigation">
    <div *ngFor="let item of menuItems; index as i" class="menu-item" [class.expanded]="item.expanded">
      <!-- Menu is not condensed or item does not have a submenu -->
      <ng-container *ngIf="!item.submenu || !condensed">
        <button
          class="menu-button"
          mat-button
          (click)="itemClick(item)"
          [routerLink]="item.link || null"
          [routerLinkActive]="item.link ? 'active' : ''"
          [queryParamsHandling]="item.link ? null : 'preserve'"
          [matTooltip]="item.title"
          [matTooltipDisabled]="!condensed"
          matTooltipPosition="right"
        >
          <i class="icon start-icon" *ngIf="item?.icon">{{ item?.icon }}</i>
          <span>{{ item.title }}</span>
          <i class="icon expand-icon" *ngIf="item?.submenu">expand_more</i>
        </button>
      </ng-container>

      <!-- Menu is condensed and item has a submenu -->
      <ng-container *ngIf="item.submenu && condensed">
        <button
          class="menu-button"
          mat-button
          [matTooltip]="item.title"
          [matTooltipDisabled]="!condensed"
          matTooltipPosition="right"
          [matMenuTriggerFor]="itemSubmenu"
        >
          <i class="icon start-icon" *ngIf="item?.icon">{{ item?.icon }}</i>
          <span>{{ item.title }}</span>
          <i class="icon expand-icon">expand_more</i>
        </button>

        <mat-menu #itemSubmenu="matMenu">
          <ng-container *ngIf="item.submenu">
            <button
              mat-menu-item
              *ngFor="let subitem of item.submenu"
              class="menu-button"
              [routerLink]="item?.link || []"
              [routerLinkActive]="item.link ? 'active' : ''"
            >
              {{ subitem.title }}
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>

      <div
        *ngIf="item.submenu && !condensed"
        class="expansion-panel"
        [ngStyle]="{ maxHeight: item?.submenu?.length * 42 + (item?.submenu?.length - 1) * 2 + 4 + 'px' }"
      >
        <button
          *ngFor="let subitem of item.submenu"
          mat-button
          class="menu-button"
          [routerLink]="subitem?.link || []"
          [routerLinkActive]="subitem.link ? 'active' : ''"
        >
          {{ subitem.title }}
        </button>
      </div>
    </div>
  </section>

  <button
    class="menu-button logout-button loading-dark"
    mat-button
    (click)="onLogout()"
    [appLoading]="isLoggingOut"
    matTooltip="Logout"
    [matTooltipDisabled]="!condensed"
    matTooltipPosition="right"
  >
    <i class="icon start-icon">logout</i>
    <span>Logout</span>
  </button>
</nav>
