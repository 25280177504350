import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Router } from '@angular/router';
import { AdminType } from 'src/app/shared/models/user';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastService } from '../../shared/services/toast.service';

export interface MenuItem {
  title: string;
  link?: string;
  icon?: string;
  submenu?: { title: string; link: string }[];
  expanded?: boolean;
  roles: AdminType[];
}

const allMenuItems: MenuItem[] = [
  // {
  //   title: 'Dashboard',
  //   link: '/admin/dashboard',
  //   icon: 'dashboard',
  //   roles: [AdminType.content, AdminType.superadmin],
  // },
  // {
  //   title: 'Admins',
  //   link: '/admin/admin-users',
  //   roles: [AdminType.superadmin],
  //   icon: 'supervised_user_circle',
  // },
  {
    title: 'Users',
    link: '/admin/users',
    icon: 'people',
    roles: [AdminType.content, AdminType.superadmin],
  },
  {
    title: 'Groups',
    link: '/admin/groups',
    icon: 'groups',
    roles: [AdminType.content, AdminType.superadmin],
  },
  {
    title: 'Events',
    link: '/admin/events',
    icon: 'attractions',
    roles: [AdminType.content, AdminType.superadmin],
  },
  {
    title: 'Payments',
    link: '/admin/payments',
    icon: 'payments',
    roles: [AdminType.content, AdminType.superadmin],
  },
  // {
  //   title: 'Demos',
  //   icon: 'play_arrow',
  //   roles: [AdminType.content, AdminType.superadmin],
  //   submenu: [
  //     {
  //       title: 'Demo table selection',
  //       link: '/admin/demos/selection',
  //     },
  //     {
  //       title: 'Demo ordering',
  //       link: '/admin/demos/ordering',
  //     },
  //   ],
  // },
];

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @ViewChildren(MatMenu) menus: QueryList<MatMenu>;

  @Input() isMobile = false;
  @Input() isCondensed = false;

  @Output() menuToggle: EventEmitter<void> = new EventEmitter<void>();
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();

  activeLink: string;
  isLoggingOut = false;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private auth: AuthService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.urlChanged(this.router.url);
  }

  get menuItems() {
    return allMenuItems.filter((item) => item.roles.includes(this.auth.user.type));
  }

  get condensed() {
    return this.isMobile ? false : this.isCondensed;
  }

  urlChanged(url) {
    this.activeLink = url.split('?')[0];
    this.setExpanded();
    this.cdr.detectChanges();
  }

  setExpanded() {
    this.menuItems
      .filter((item) => item.submenu != null)
      .forEach((item) => {
        item.expanded = item.submenu.map((i) => i.link).includes(this.activeLink);
      });
  }

  async onLogout() {
    try {
      this.isLoggingOut = true;
      await this.auth.logout();
      this.logout.emit();
    } catch (error) {
      this.toast.showError(error);
    } finally {
      this.isLoggingOut = false;
    }
  }

  itemClick(item: MenuItem) {
    if (item.submenu && !this.condensed) {
      item.expanded = !item.expanded;
    }
  }
}
