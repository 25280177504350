import { BaseModel } from './base-model';

export enum UserType {
  customer = 'customer',
}

export enum AdminType {
  superadmin = 'superadmin',
  content = 'content',
}
export const AdminTypeAll = Object.values(AdminType);

export enum UserStatus {
  active = 'active',
  suspended = 'suspended',
}

interface BaseUser extends BaseModel {
  phone: string;
  status: UserStatus;
}

export interface User extends BaseUser {
  name?: string;
  profilePicture?: string;
  profilePictureUrl?: string;
  type: UserType;
}

export interface Admin extends BaseUser {
  type: AdminType;
  // only for editing
  password?: string;
}

/**
 * Used to specify a user's role in a group / event
 */
export enum UserRole {
  organizer = 'organizer',
  participant = 'participant',
}
