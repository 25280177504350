import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Upload, UploadData, UploadRequest, UploadResponse } from '../models/upload';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpClient) {}

  public async uploadFile(data: UploadRequest): Promise<UploadResponse> {
    const requestObj = { ...data, mimetype: data.file.type };
    delete requestObj.file;

    const uploadData = await this.http.post<UploadData>('uploads', requestObj).toPromise();
    const formData = new FormData();

    Object.entries(uploadData.upload.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('file', data.file);

    const resp = await this.http.post<UploadResponse>(uploadData.upload.url, formData).toPromise();
    return resp;
  }

  public async getUploadByKey(key: string): Promise<Upload> {
    return this.http.get<Upload>('uploads', { params: { key } }).toPromise();
  }
}
